<template>
  <div>
    <div class="flex items-center">
      <h3 class="text-info">{{ this.$t('Información personal') }}</h3>
      <!-- <vs-button @click="toggleEditProfile()" color="transparent">
        <img v-if="editProfile" :src="require(`@/assets/icons/EditCircleBlueIcon.svg`)" width="32px" />
        <img v-else :src="require(`@/assets/icons/EditCircleWhiteIcon.svg`)" width="32px" />
      </vs-button> -->
    </div>

    <vx-card class="mt-5 shadow-none" :card-background="editProfile ? 'white' : 'transparent'">
      <form @submit.prevent="updateProfileData()">
        <div class="vx-row">
          <!-- Nombre completo -->
          <div class="vx-col lg:w-1/2 xs:w-full mt-5">
            <label for="nit" class="vs-input--label">{{ this.$t('Nombre completo') }}</label>
            <vs-input
              name="name"
              v-model="user.name"
              class="w-full"
              :disabled="!editProfile"
              :danger="!!validate.nameError"
              val-icon-danger="clear"
              :danger-text="validate.nameError"
              style="background-color: white !important;"
            />
          </div>

          <!-- No. de Identificación -->
          <div class="vx-col lg:w-1/2 xs:w-full mt-5">
            <label for="nit" class="vs-input--label">{{ this.$t('Identificación') }}</label>
                  
            <div class="flex items-center">
              <vs-input
                id="doc_type"
                name="doc_type"
                class="sm:w-1/3 xs:w-1/4"
                :disabled="true"
                readonly
                value="CNPJ"
                style="background-color: white !important;"
              />

              <vs-input
                id="nit"
                name="nit"
                class="sm:w-2/3 xs:w-3/4"
                v-model="user.identification"
                :disabled="!editProfile"
                style="background-color: white !important;"
                readonly
              />
            </div>

            <p class="text-danger text-sm" v-if="!!validate.nitError">{{ validate.nitError }}</p>
          </div>

          <!-- empresa -->
          <div class="vx-col lg:w-1/2 xs:w-full mt-5">
            <label for="nit" class="vs-input--label">{{ this.$t('Empresa') }}</label>
            <vs-input
              name="companies_neme"
              v-model="app_name"
              class=" w-full"
              disabled
              style="background-color: white !important;"
            />
          </div>

          <!-- Cargo -->
          <div class="vx-col lg:w-1/2 xs:w-full mt-5">
            <label for="nit" class="vs-input--label">{{ this.$t('Cargo') }}</label>
            <vs-input
              name="position"
              v-model="user.position"
              class="w-full"
              :disabled="!editProfile"
              style="background-color: white !important;"
            />
          </div>

          <!-- Telefono fijo -->
          <div class="vx-col lg:w-1/2 xs:w-full mt-5">
            <label for="phone" class="vs-input--label">{{ this.$t('Teléfono fijo') }}</label>

            <div class="flex">
              <div class="card flags sm:w-1/3 xs:w-2/4">
                <img :src="require('@/assets/images/flags/br.png')" class="rounded-full inline-block align-middle" width="32px" />&nbsp;
                <span class="inline-block align-middle">+55</span>
              </div>
              
              <!-- <v-select :options="callsigns" label="sign" :reduce="sign => sign.sign" v-model="user.country_code_phone" :disabled="true" class="sm:w-1/3 xs:w-2/4">
                <template slot="selected-option" slot-scope="option">
                  <img :src="require('@/assets/images/flags/br.png')" class="rounded-full" width="32px" />&nbsp;
                  <span class="inline-block align-middle">{{ option.sign }}</span>
                </template>
              </v-select> -->

              <vs-input
                id="phone"
                name="phone"
                class="sm:w-2/3 xs:w-2/4"
                v-model="user.phone"
                :disabled="!editProfile"
                style="background-color: white !important;"
              />
            </div>
          </div>

          <!-- telefono celular -->
          <div class="vx-col lg:w-1/2 xs:w-full mt-5">
            <label for="cellphone" class="vs-input--label">{{ this.$t('Celular') }}</label>

            <div class="flex">
              <div class="card flags sm:w-1/3 xs:w-2/4">
                <img :src="require('@/assets/images/flags/br.png')" class="rounded-full inline-block align-middle" width="32px" />&nbsp;
                <span class="inline-block align-middle">+55</span>
              </div>
              <!-- <v-input label="sign" :reduce="sign => sign.sign" v-model="user.country_code_cell_phone" :disabled="true" class="sm:w-1/3 xs:w-2/4">
                <template slot="selected-option" slot-scope="option">
                  <img :src="require('@/assets/images/flags/br.png')" class="rounded-full inline-block align-middle" width="32px" />&nbsp;
                  <span class="inline-block align-middle">{{ option.sign }}</span>
                </template>
              </v-input>
 -->
              <vs-input
                id="cellphone"
                name="cellphone"
                v-model="user.cell_phone"
                class="sm:w-2/3 xs:w-2/4 flag_input"
                style="background-color: white !important;"
                :disabled="true"
              />
            </div>
          </div>
                
          <!-- boton guardar -->
          <!-- <div class="vx-col w-full">
            <button v-if="editProfile" type="submit" class="vs-component vs-button vs-button-primary vs-button-relief rounded-full float-right mt-10">
              {{ this.$t('Guardar Cambios') }}
            </button>
          </div> -->
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script lang="ts">
import vSelect from 'vue-select';
import Vue     from 'vue';
import Cookies from 'js-cookie';

import { Validation } from '../../../plugins/validation.js';

export default Vue.extend({
  props: ['userdata'],
  data() {
    return {
      documentTypes: ['CC', 'CE', 'NIT'],
      documentTypeSelected: 'CC',
      callsignSelected: '+55',
      editProfile: false,
      clicks: 0,
      validate: new Validation(),
      app_name: '',
      user: {
        identification: '',
        name: '',
        phone: '',
        cell_phone: '',
        position: '',
        role: ''
      }
    }
  },

  async created() {
    await this.$store.dispatch( 'location/getAllSigns' );
    //await this.$store.dispatch( 'location/getAllCities' );
    // SET plus (+) sing;
    const userInfo = JSON.parse(Cookies.get('userInfo'));
    this.user.country_code_phone = `+55`;
    this.user.country_code_cell_phone = `+55`;
    this.user.identification = userInfo.person.identification;
    this.user.name = userInfo.person.name
    this.user.phone = userInfo.person.cell_phone;
    this.user.cell_phone = userInfo.person.cell_phone;
    this.user.position = userInfo.auth_grants.role;
    
    const company = userInfo.company;
    this.app_name = company.app_name;
  },

  watch: {
  },

  methods: {
    toggleEditProfile() {                      
      this.editProfile = !this.editProfile;
    },

    async updateProfileData() {
      const isValidName = this.validate.validateName( this.user.name );

      if( !isValidName ) {
        this.$vs.notify({
          title: this.$t('Valide los campos antes de continuar'),
          text: '',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 3900,
        });
        return false;
      }

      const userStores = this.user.store;
      const userStoresMapped = userStores.map( store => store.id );
      const userInfo = JSON.parse( Cookies.get('userInfo') );
      const citiesFinded = this.cities.filter( city => city.name === this.user.city_name );
      const city = citiesFinded[0];

      const user = {
        ...this.user,
        lastname        : ' ',
        username        : userInfo.person.email,
        email           : userInfo.person.email,
        uid             : userInfo.uid,
        city_dane       : city.dane,
        country_code_cp : this.user.country_code_cell_phone,
        country_code_p  : this.user.country_code_phone,
        shops_list      : userStoresMapped.toString(),
      };
            
      this.$vs.loading();
      const res = await this.$store.dispatch( 'users/updateUser', user );
      this.$vs.loading.close();

      if( res ) {
        this.getUser();
        this.$vs.notify({
          title: this.$t('Se ha guardado con éxito'),
          text: '',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
          time: 4000,
        });

        this.editProfile = false;

      } else {
        this.$vs.notify({
          title: this.$t('Ocurrió un error'),
          text: this.$t('Intente de nuevo más tarde'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 4000,
        });

      }
    },

    async getUser() {
      this.$vs.loading();
      const userInfo = JSON.parse( Cookies.get('userInfo') );
      if (userInfo.auth_grants.role != "USERS_SELLER"){
        await this.$store.dispatch( 'users/getUserById', userInfo.user.id );
      } else {
        this.user.identification = userInfo.person.identification
      }
      //await this.$store.dispatch( 'users/getUserById', userInfo.user.id );
      this.$vs.loading.close();
    }
  },

  computed: {
    callsigns() {
      const callsigns = this.$store.state.location.callsigns;
      const signs = callsigns.map(sign => {
        let ob = Object.assign({}, sign);
        ob.sign = '+55';
        
        return ob;
      });

      return signs;
    },

    cities() { return [...this.$store.state.location.cities]; },
  },

  components: {
    'v-select': vSelect,
  }
});
</script>

<style lang="scss">
  .flags{
    padding: 7px;
    border: 1px #dededf solid;
    /* border-radius: 0px; */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .flag_input{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
</style>