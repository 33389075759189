<template>
	<div class="vx-row">
		<div class="vx-col w-full">
			<div class="ml-10 flex space-x-4">
				<vs-avatar v-if="userdata.photoURL" size="80px" :src="userdata.photoURL" />

				<vs-button v-else radius color="white" text-color="rgb(30,30,30)" class="cursor-pointer h-24 w-24">
					<img :src="require(`@/assets/icons/${icon}.svg`)" width="32px" />
				</vs-button>

				<div v-if="userdata">
					<h1>{{userdata.person.name}}</h1>
					<p>{{ this.$t('Perfil Administrativo') }}</p>
				</div>
			</div>
		</div>
		<div class="vx-col w-full mt-10">
			<personal-data :userdata="userdata"></personal-data>
		</div>
		<!-- 
		<div class="vx-col w-full mt-10">
			<change-password :userdata="userdata"></change-password>
		</div> -->
	</div>
</template>

<script lang="ts">
import Vue     from 'vue';
import Cookies from 'js-cookie';

import PersonalData from './PersonalData.vue';
import ChangePassword from './ChangePassword.vue';

export default Vue.extend({

	data() {
		return {
			icon : 'UserIcon',
			userdata: {name:'', person:{name:''},photoURL:'', auth_grants:{role: ''}}
		}
	},

	async created() {
		this.$vs.loading();
		await this.$store.dispatch( 'updatePageTitle', { title: this.$t('Perfil'), subtitle: this.$t('Información sobre ti') } );
		const userInfo = JSON.parse( Cookies.get('userInfo') );
		this.userdata = userInfo
		this.$vs.loading.close();
	},

    computed: {
    },

    components: {
        PersonalData,
        ChangePassword,
    },
})
</script>