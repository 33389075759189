<template>
  <div>
    <div class="flex items-center">
      <h3 class="text-info">Datos de Cuenta</h3>
      
      <!-- <vs-button @click="toggleEditProfile()" color="transparent">
        <img v-if="editProfile" :src="require(`@/assets/icons/EditCircleBlueIcon.svg`)" width="32px" />
        <img v-else :src="require(`@/assets/icons/EditCircleWhiteIcon.svg`)" width="32px" />
      </vs-button> -->
    </div>

    <vx-card class="mt-5 shadow-none" :card-background="editProfile ? 'white' : 'transparent'">
      <form @submit.prevent="updatePassword()">
        <div class="vx-row">
          <!-- Rol -->
          <div class="vx-col lg:w-1/2 xs:w-full xs:mb-5">
            <vs-input
              label="rol"
              class="w-full"
              name="role"
              :value="$t(user.role_name)"
              disabled
            />
          </div>
          <!-- correo -->
          <div class="vx-col lg:w-1/2 xs:w-full xs:mb-5">
            <vs-input
              label="Correo electrónico"
              class="w-full"
              name="email"
              v-model="email"
              disabled
            />
          </div>
          <!-- contraseña 1 -->
          <div class="vx-col lg:w-2/5 xs:w-full xs:mb-5">
            <label for="password">
              <b>Contraseña</b>
              <p> <i class="text-xs">(Mínimo 6 caracteres 1 Mayúscula y/o Símbolo)</i> </p>
            </label>

            <vx-input-group>
              <vs-input
                :danger="!!validate.passwordError"
                
                @keyup="validate.validatePassword(password)"
                id="password"
                name="password"
                :type=" isVisiblePassword ? 'text' : 'password' "
                v-model="password"
                :disabled="true"
              />

              <template slot="append" v-if="editProfile">
                <div class="append-text btn-addon">
                  <vs-button
                    color="dark"
                    type="flat"
                    icon-pack="feather"
                    :icon=" isVisiblePassword ? 'icon-eye' : 'icon-eye-off'"
                    @click="isVisiblePassword = !isVisiblePassword"
                  ></vs-button>
                </div>
              </template>
            </vx-input-group>
            <p class="text-danger text-sm" v-if="!!validate.passwordError">{{ validate.passwordError }}</p>
          </div>
          <!-- Contraseña 2 -->
          <div class="vx-col lg:w-2/5 xs:w-full" v-if="editProfile">
            <label for="password2">
              <p><b>Confirmar Contraseña</b></p>
              <br/>
            </label>
                        
            <vx-input-group>
              <vs-input
                :danger="!!validate.password2Error"
                val-icon-danger="clear"
                @keyup="validate.validatePassword2( password, password2 )"
                id="password2"
                name="password2"
                :type=" isVisiblePassword2 ? 'text' : 'password' "
                v-model="password2"
              />

              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button
                    color="dark"
                    type="flat"
                    icon-pack="feather"
                    :icon=" isVisiblePassword2 ? 'icon-eye' : 'icon-eye-off'"
                    @click="isVisiblePassword2 = !isVisiblePassword2"
                  ></vs-button>
                </div>
              </template>
            </vx-input-group>
            <p class="text-danger text-sm" v-if="!!validate.password2Error">{{ validate.password2Error }}</p>
          </div>
          <!-- Generar contraseña -->
          <div class="vx-col lg:w-1/5 xs:invisible lg:visible" v-if="editProfile">
            <br/><br/>
            <button
              v-if="editProfile"
              type="button"
              class="vs-component vs-button bg-info vs-button-relief rounded-full float-right w-full"
              @click="generatePassword()"
            >
              <feather-icon icon="ClipboardIcon" class="align-middle mr-2"></feather-icon>
              Generar
            </button>
          </div>
          <!-- Botón guardar -->
          <div class="vx-col w-full mt-10 mb-10">
            <button
              v-if="editProfile"
              type="submit"
              class="vs-component vs-button vs-button-primary vs-button-relief rounded-full float-right lg:w-64 xs:w-full"
            >
              Guardar Cambios
            </button>
          </div>
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script lang="ts">
import vSelect from 'vue-select';
import Vue     from 'vue'
import Cookies from 'js-cookie'

import { Validation } from '../../../plugins/validation.js';

export default Vue.extend({
  props: {
    userdata: { required: true },
  },

  data() {
    return {
      editProfile: false,
      password: '********',
      password2: '',
      isVisiblePassword: false,
      isVisiblePassword2: false,
      validate: new Validation(),
    }
  },

  methods: {
    toggleEditProfile( field=null ) {
      this.editProfile = !this.editProfile;

      const fieldName = field ? field : 'password';    
      const el = document.getElementById(fieldName);
      setTimeout(() => {
        el.focus();
      }, 1);

      if( this.password === '' ) this.password = '********';
      else this.password = '';
    },

    async updatePassword() {
      const isValidPassword = this.validate.validatePassword(this.password);
      const isValidPassword2 = this.validate.validatePassword2( this.password, this.password2 );

      if( !isValidPassword || !isValidPassword2 ) {
        return this.$vs.notify({
          title: 'Alerta',
          text: 'Valide las contraseñas antes de continuar',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });
      }
        
      this.$vs.loading();
      const res = await this.$store.dispatch( 'auth/changePassword', this.password )
      this.$vs.loading.close();
                
      if( res.err ) {
        this.getUser();
        return this.$vs.notify({
          title: 'Error',
          text: this.$t(res.err),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 7000,
        });

      } else {
        this.$vs.notify({
          title: 'Correcto',
          text: 'Contraseña Cambiada',
          iconPack: 'feather',
          icon: 'icon-smile',
          color: 'success',
          time: 7000
        });

        this.toggleEditProfile();
      }
    },

    generatePassword() {
      let res = '';
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+*<>_-)(/&%$#!';
      const charsLenght = chars.length;

      for(let i = 0; i < 29; i++) {
        res += chars.charAt( Math.floor ( Math.random() * charsLenght) );
      }

      this.password = res;

      const isValidPassword = this.validate.validatePassword(this.password);
        
      if(!isValidPassword) {
        this.password = '';
        this.generatePassword();
        return;
      }

      if( !navigator.clipboard ) {
        this.$vs.notify({
          title: 'Error',
          text: 'No se pudo copiar la contraseña',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 7000,
        });

      } else {
        navigator.clipboard.writeText(this.password);
        this.$vs.notify({
          title: 'Correcto',
          text: 'Contraseña copiada',
          iconPack: 'feather',
          icon: 'icon-smile',
          color: 'success',
          time: 7000
        });
      }

      this.password = res;
      this.password2 = res;
      this.validate.validatePassword2();
    },

    async getUser() {
      this.$vs.loading();
      const userInfo = JSON.parse( Cookies.get('userInfo') );
      await this.$store.dispatch( 'users/getUserById', userInfo.user.id );
      this.$vs.loading.close();
    }
  },

  computed: {
    validateForm() { return !this.errors.any() && this.email != '' && this.password != ''; },
    user() { return this.$store.state.users.user; },
    email() {
      const userInfo = JSON.parse( Cookies.get('userInfo') );
      const email = userInfo.person.email;
      return email;
    },
  },

  components: {
    'v-select': vSelect,
  },
});
</script>
